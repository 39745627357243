// @ts-nocheck
/* eslint-disable react/jsx-filename-extension */
import { Component } from 'react';
import { UsingKeyboard } from '@accessible/using-keyboard';
import { Icon, IconButtonTertiary } from '@knapsack/toby';
import styles from './node-content-renderer.module.scss';

function isDescendant(older, younger) {
  return (
    !!older.children &&
    typeof older.children !== 'function' &&
    older.children.some(
      (child) => child === younger || isDescendant(child, younger),
    )
  );
}

// eslint-disable-next-line react/prefer-stateless-function
class CustomThemeNodeContentRenderer extends Component {
  render() {
    const {
      // scaffoldBlockPxWidth,
      toggleChildrenVisibility,
      connectDragPreview,
      connectDragSource,
      isDragging,
      canDrop,
      canDrag,
      node,
      title,
      // subtitle,
      draggedNode,
      path,
      treeIndex,
      isSearchMatch,
      isSearchFocus,
      // icons,
      // buttons,
      className,
      style = {},
      didDrop,
      // lowerSiblingCounts,
      // listIndex,
      // swapFrom,
      // swapLength,
      // swapDepth,
      // treeId, // Not needed, but preserved for other renderers
      // isOver, // Not needed, but preserved for other renderers
      parentNode, // Needed for dndManager
      // ...otherProps
    } = this.props;
    const nodeTitle = title || node.title;
    // const nodeSubtitle = subtitle || node.subtitle;

    const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
    const isLandingPadActive = !didDrop && isDragging;

    const isLastChild =
      (node.children && !node.expanded) ||
      (!node.children &&
        path &&
        !parentNode?.path &&
        !parentNode?.parentNode &&
        parentNode?.children[parentNode.children.length - 1].id === node.id) ||
      (!node.expanded && !parentNode) ||
      (!parentNode && !node.children);

    const isExpandable =
      toggleChildrenVisibility &&
      node.children &&
      (node.children.length > 0 || typeof node.children === 'function');

    function handleToggleExpand(e) {
      if (!isExpandable) return;
      const classListString = e.target.classList.toString();

      // Prevent toggling when interacting with an input
      // (e.g. renaming an item or typing in the add entity form)
      if (e.target.tagName === 'INPUT') return;

      // Handle click checks
      if (
        e.type === 'click' &&
        !classListString.includes('ks-c-legacy-nav-item') &&
        !classListString.includes('ks-c-nav-row') &&
        !classListString.includes('rstcustom__rowLink')
      )
        return;

      // Handle keydown checks
      if (e.type === 'keydown' && e.key !== 'Enter' && e.key !== ' ') return;

      toggleChildrenVisibility({
        node,
        path,
        treeIndex,
      });
    }

    const nodeContent = connectDragPreview(
      <div
        className={`
          ${'ks-c-nav-row '}
          ${isExpandable ? 'ks-c-nav-row--expandable ' : ' '}
          ${
            node.expanded
              ? 'ks-c-nav-row--expanded '
              : 'ks-c-nav-row--collapsed '
          }
          ${node.path ? 'ks-c-nav-row--link' : 'ks-c-nav-row--toggle'}
          ${isLandingPadActive ? ` ${styles.rowLandingPad}` : ''}
          ${isLandingPadActive && !canDrop ? ` ${styles.rowCancelPad}` : ''}
          ${isSearchMatch ? `ks-c-nav-row--is-search-match ` : ''}
          ${isSearchFocus ? `ks-c-nav-row--is-search-focus` : ''}
          ${className ? ` ${className}` : ''}
        `}
        {...(isExpandable &&
          !node.path && {
            tabIndex: 0,
            role: 'button',
            'aria-label': `${node.expanded ? 'Collapse' : 'Expand'} ${
              node.name
            }`,
            'aria-expanded': node.expanded,
          })}
        {...(isExpandable && {
          onKeyDown: (e) => handleToggleExpand(e),
          onClick: (e) => handleToggleExpand(e),
        })}
        style={{
          opacity: isDraggedDescendant ? 0.5 : 1,
          ...style,
        }}
      >
        {typeof nodeTitle === 'function'
          ? nodeTitle({
              node,
              path,
              treeIndex,
            })
          : nodeTitle}

        {isExpandable && !node.path && (
          <Icon
            symbol="caret-down"
            size="small"
            className={`
              ${'ks-c-legacy-nav-item__toggle-icon '}
              ${
                node.expanded
                  ? 'ks-c-legacy-nav-item__toggle-icon--expanded'
                  : 'ks-c-legacy-nav-item__toggle-icon--collapsed'
              }
            `}
          />
        )}
        {isExpandable && node.path && (
          <IconButtonTertiary
            icon={node.expanded ? 'caret-up' : 'caret-down'}
            size="small"
            type="button"
            color="inherit"
            label={`${node.expanded ? 'Collapse' : 'Expand'} ${node.name}`}
            testId="secondary-nav-dropdown"
            onTrigger={({ event }) => {
              // Prevent parent keydown handler from firing
              event.preventDefault();
              event.stopPropagation();

              toggleChildrenVisibility({
                node,
                path,
                treeIndex,
              });
            }}
          />
        )}
      </div>,
    );

    return (
      <div
        className={`ks-c-legacy-nav-item__inner
        ${isDragging ? 'ks-c-legacy-nav-item__inner--dragging' : ''}`}
      >
        <UsingKeyboard className="is-using-keyboard">
          {canDrag
            ? connectDragSource(nodeContent, { dropEffect: 'grabbing' })
            : nodeContent}
        </UsingKeyboard>
      </div>
    );
  }
}

// CustomThemeNodeContentRenderer.propTypes = {
//   ...propTypes,
//   // buttons: PropTypes.arrayOf(PropTypes.node),
//   // eslint-disable-next-line react/boolean-prop-naming
//   canDrag: PropTypes.bool,
//   className: PropTypes.string,
//   // icons: PropTypes.arrayOf(PropTypes.node),
//   isSearchFocus: PropTypes.bool,
//   isSearchMatch: PropTypes.bool,
//   node: PropTypes.shape({}).isRequired,
//   path: PropTypes.arrayOf(
//     PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//   ).isRequired,
//   // scaffoldBlockPxWidth: PropTypes.number.isRequired,
//   style: PropTypes.shape({}),
//   // subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
//   // swapDepth: PropTypes.number,
//   // swapFrom: PropTypes.number,
//   // swapLength: PropTypes.number,
//   title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
//   toggleChildrenVisibility: PropTypes.func,
//   treeIndex: PropTypes.number.isRequired,
//   // treeId: PropTypes.string.isRequired,

//   // Drag and drop API functions
//   // Drag source
//   connectDragPreview: PropTypes.func.isRequired,
//   connectDragSource: PropTypes.func.isRequired,
//   // eslint-disable-next-line react/boolean-prop-naming
//   didDrop: PropTypes.bool.isRequired,
//   draggedNode: PropTypes.shape({}),
//   isDragging: PropTypes.bool.isRequired,
//   parentNode: PropTypes.shape({}), // Needed for dndManager
//   // Drop target
//   // eslint-disable-next-line react/boolean-prop-naming
//   canDrop: PropTypes.bool,
//   // isOver: PropTypes.bool.isRequired,
// };

export default CustomThemeNodeContentRenderer;
