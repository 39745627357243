import { lazy, useCallback, useRef, useState } from 'react';
import Link from 'next/link';
import { KnapsackNavItem } from '@knapsack/types';
import { Flex, Icon, IconButtonTertiary, TextLabel } from '@knapsack/toby';
import { useKsNav } from '@/utils/routes';
import { horizontalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { isRemoteUrl } from '@knapsack/utils';
import { useNavItems } from '../hooks';
import {
  primaryNavItem,
  primaryNavItem__menuTrigger,
  primaryNavItem_link,
} from './primary-nav.css';
import { getTopNavItem } from './primary-nav.utils';

// Lazy load elements that are not available in view mode
const NavItemInlineRename = lazy(
  () => import('../components/nav-item-inline-rename'),
);
const NavMenu = lazy(() => import('../components/nav-menu/nav-menu'));

type PrimaryNavItemProps = {
  navItem: KnapsackNavItem;
  canEdit?: boolean;
  isActive?: boolean;
};

export const PrimaryNavItem = ({
  navItem,
  canEdit = false,
  isActive = false,
}: PrimaryNavItemProps) => {
  const { createUrl } = useKsNav();

  const isNavItemExternal = isRemoteUrl(navItem.path);

  // Navs Data
  const navItems = useNavItems({ includeHiddenItems: true });

  // State Management
  const [isRenaming, setIsRenaming] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [menuTriggerIsActive, setMenuTriggerIsActive] = useState(false);
  const menuRef = useRef<HTMLButtonElement>(null);

  // Drag and Drop
  const {
    attributes: dragAttributes,
    listeners: dragListeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: navItem.id,
    disabled: isRenaming,
    strategy: horizontalListSortingStrategy,
  });
  const dragAndDropStyle = {
    transform: CSS.Transform.toString({
      scaleX: 1,
      scaleY: 1,
      x: transform?.x ?? 0,
      y: transform?.y ?? 0,
    }),
    transition,
  };

  const generateLinkPath = useCallback(
    ({ path, id }: { path: string; id: string }) => {
      if (path) {
        return createUrl(path);
      }
      try {
        const firstSubItem = navItems
          .filter((e) => !isRemoteUrl(e.path))
          .find(
            (item) =>
              getTopNavItem({
                allNavItems: navItems,
                navItem: item,
              }).id === id && !!item.path,
          );
        if (firstSubItem) {
          return createUrl(firstSubItem.path);
        }
      } catch (e) {
        import('@knapsack/toby').then(({ createToast }) => {
          if (e instanceof Error) {
            createToast({
              type: 'error',
              message: e.message,
            });
          }
        });
      }

      // fallback is to just head home
      return createUrl('/');
    },
    [createUrl, navItems],
  );

  return (
    <li
      className={primaryNavItem({
        canEdit,
        isActive,
        isDragging,
        isHidden: navItem.hidden,
      })}
      ref={setNodeRef}
      style={dragAndDropStyle}
      data-test-id={isActive ? 'primaryNavItem--active' : 'primaryNavItem'}
    >
      {canEdit && (
        <>
          <IconButtonTertiary
            {...dragListeners}
            {...(canEdit ? dragAttributes : {})}
            className={primaryNavItem__menuTrigger({
              isActive: menuTriggerIsActive,
            })}
            icon="drag"
            label="Manage Item"
            testId={`manageItem--${navItem.id}`}
            onTrigger={() => {
              setMenuTriggerIsActive(true);
              setMenuIsOpen((prev) => !prev);
            }}
            ref={menuRef}
            tooltipContent={
              <Flex direction="column" align="start">
                <TextLabel size="small" weight="bold" display="block">
                  Drag <TextLabel size="small">to reorder.</TextLabel>
                </TextLabel>
                <TextLabel size="small" weight="bold">
                  Click <TextLabel size="small">to open menu.</TextLabel>
                </TextLabel>
              </Flex>
            }
            size="xsmall"
            color="inherit"
          />
          <NavMenu
            anchorEl={menuRef.current}
            navItem={navItem}
            onClose={() => {
              setMenuTriggerIsActive(false);
              setMenuIsOpen(false);
            }}
            open={menuIsOpen}
            onTriggerRename={() => setIsRenaming(true)}
          />
        </>
      )}

      {isRenaming ? (
        <NavItemInlineRename
          navItem={navItem}
          onRenameEnd={() => setIsRenaming(false)}
        />
      ) : (
        <Link
          className={primaryNavItem_link({
            canEdit,
            isHidden: navItem.hidden,
          })}
          target={isNavItemExternal ? '_blank' : undefined}
          href={{
            pathname: isNavItemExternal
              ? navItem.path
              : generateLinkPath({ path: navItem.path, id: navItem.id }),
          }}
        >
          {navItem.minRoleNeeded && (
            <Icon
              symbol="locked"
              size="xsmall"
              className="primary-nav-locked-icon"
            />
          )}
          {navItem.name}
          {isNavItemExternal && <Icon symbol="link-external" size="xsmall" />}
        </Link>
      )}
    </li>
  );
};
