import { useState, useRef, lazy } from 'react';
import { IconButtonTertiary } from '@knapsack/toby';
import { useNavItem } from '../../hooks';

const NavMenu = lazy(() => import('../nav-menu/nav-menu'));
const AddNewTrigger = lazy(
  () => import('@/domains/navs/components/add-new/add-new-trigger'),
);

type NavTreeItemActionsProps = {
  id: string;
  handleRename: () => void;
  external: boolean;
};

export const NavTreeItemActions = ({
  id,
  handleRename,
  external = false,
}: NavTreeItemActionsProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLButtonElement>(null);

  // Get navItem from node id
  const navItem = useNavItem({ id });

  return (
    <>
      {!external && (
        <AddNewTrigger
          label="Add Subpage"
          menuPlacement="bottom-end"
          parentId={id}
          testId="add-entity--trigger--sidebar"
          trigger={{
            type: 'button',
            buttonColor: 'default',
            buttonIconOnly: true,
            buttonSize: 'xsmall',
          }}
        />
      )}
      <IconButtonTertiary
        className={isMenuOpen ? 'is-toggled' : ''}
        icon="more"
        label="Manage Item"
        testId={`manageItem--${id}`}
        onTrigger={({ event }) => {
          // Prevent parent handler from firing
          event.preventDefault();
          event.stopPropagation();

          setIsMenuOpen(!isMenuOpen);
        }}
        ref={menuRef}
        size="xsmall"
      />
      <NavMenu
        anchorEl={menuRef.current}
        navItem={navItem}
        onClose={() => setIsMenuOpen(false)}
        open={isMenuOpen}
        onTriggerRename={() => handleRename()}
        placement="bottom-end"
      />
    </>
  );
};
