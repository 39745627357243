import { useState } from 'react';
import cx from 'clsx';
import {
  SortableTreeWithoutDndContext as SortableTree,
  TreeItem,
} from 'react-sortable-tree';
import { sendUiEvent, useUiCtxSelector, useUiStateMatches } from '@/core';
import { isRemoteUrl } from '@knapsack/utils';
import { reactSortableTreeTheme } from './react-sortable-tree-theme';
import { NavTreeItem } from './nav-tree-item';

type Props = {
  canEdit?: boolean;
  onMoveNode?: ({
    node,
    nextParentNode,
    treeData,
  }: {
    node: TreeItem;
    treeData: TreeItem[];
    nextParentNode: TreeItem;
  }) => void;
  handleNewTreeItems?: (newNavItems: TreeItem[]) => void;
  ariaLabel: string;
  treeItems: TreeItem[];
};

export const NavTree = ({
  canEdit,
  handleNewTreeItems = () => {},
  ariaLabel,
  treeItems,
  onMoveNode,
}: Props) => {
  const [isDragging, setIfDragging] = useState(false);
  const classes = cx('ks-secondary-nav', 'ks-test-secondary-nav', {
    'is-dragging': isDragging,
  });
  const expandedNavIds = useUiCtxSelector((ctx) => ctx.expandedNavIds);

  const isEditingNav = useUiStateMatches('navEdit.editing');

  function expandTreeItems(items: TreeItem[]) {
    return items.map((item) => {
      if (expandedNavIds.includes(item.id)) {
        item.expanded = true;
      }
      if (item.children?.length > 0) {
        item.children = expandTreeItems(item.children as TreeItem[]);
      }
      return item;
    });
  }

  if (!treeItems) return null;

  return (
    <nav className={classes} aria-label={ariaLabel}>
      <SortableTree
        onMoveNode={({ node, treeData, nextParentNode }) => {
          onMoveNode?.({ node, treeData, nextParentNode });
        }}
        isVirtualized={false}
        treeData={expandTreeItems(treeItems)}
        canNodeHaveChildren={(node) => !isRemoteUrl(node.path)}
        canDrag={canEdit && !isEditingNav && treeItems.length > 0}
        scaffoldBlockPxWidth={20}
        rowHeight={30}
        // default `placeholderRenderer` uses `defaultProps` which is deprecated. also we didn't use a placeholder tree for rendering
        placeholderRenderer={() => <div />}
        onChange={(newTreeData) =>
          newTreeData && handleNewTreeItems(newTreeData)
        }
        getNodeKey={(data) => data?.node?.id || JSON.stringify(data)}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore unknown prop type
        theme={reactSortableTreeTheme}
        onlyExpandSearchedNodes
        maxDepth={4}
        onVisibilityToggle={({ node, expanded }) => {
          sendUiEvent({
            type: 'nav.setExpandedNavIds',
            add: expanded && node.id,
            remove: !expanded && node.id,
          });
          setTimeout(() => {
            setIfDragging(false);
          }, 0);
        }}
        onDragStateChanged={(data) => {
          setIfDragging(data.isDragging);
        }}
        generateNodeProps={(data) => {
          const { parentNode, node } = data;
          return {
            title: (
              <NavTreeItem
                canEdit={canEdit}
                isDragging={isDragging}
                node={node}
                parentNode={parentNode}
              />
            ),
          };
        }}
      />
    </nav>
  );
};

export default NavTree;
