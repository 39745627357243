import type { KnapsackNavItem, KsAppClientData, Role } from '@knapsack/types';
import { getTypeFromPathMemo } from '@/utils/routes';

export function getNavItemInfo({
  navItem,
  appClientData: { patternsState, customPagesState },
}: {
  appClientData: KsAppClientData;
  navItem: Pick<KnapsackNavItem, 'path' | 'name'>;
}) {
  const { contentType, contentId } = getTypeFromPathMemo(navItem.path);
  let title = navItem.name || navItem.path;
  switch (contentType) {
    case 'pattern': {
      title = patternsState.patterns?.[contentId]?.title;
      break;
    }
    case 'page': {
      title = customPagesState.pages?.[contentId]?.title;
      break;
    }
  }
  return { title, contentType, contentId };
}

export const userCanAccessItem = ({
  userRole,
  minRoleNeeded,
}: {
  userRole: Role;
  minRoleNeeded: Role;
}) => {
  switch (minRoleNeeded) {
    case 'ADMIN':
      return userRole === 'ADMIN';
    case 'CONTRIBUTOR':
      return userRole === 'ADMIN' || userRole === 'CONTRIBUTOR';
    default:
      return true;
  }
};
