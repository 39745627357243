import { useState, useRef, useEffect, useCallback } from 'react';
import { TreeItem } from 'react-sortable-tree';
import Link from 'next/link';
import { Icon, componentSpacing, createToast } from '@knapsack/toby';
import { useKsNav } from '@/utils/routes';
import { sendUiEvent, useSelector, sendAppClientDataEvent } from '@/core';
import { InlineEditErrorMsgs } from '@/components/inline-edit';
import './nav-tree-item.scss';
import { isRemoteUrl } from '@knapsack/utils';
import { usePathname } from 'next/navigation';
import { NavTreeItemActions } from './nav-tree-item.actions';
import { getNavItemInfo } from '../../utils/nav-item-info';

type NavTreeItemProps = {
  node: TreeItem;
  isDragging?: boolean;
  canEdit?: boolean;
  parentNode: TreeItem;
};

export const NavTreeItem = ({
  node,
  isDragging,
  canEdit,
  parentNode,
}: NavTreeItemProps) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const { id, path, hidden: isHidden, minRoleNeeded, name } = node;

  const isNavItemExternal = isRemoteUrl(path);

  const { createUrl } = useKsNav();

  const {
    contentId,
    contentType,
    title: currentNodeTitle,
  } = useSelector(
    (s) => {
      return getNavItemInfo({
        appClientData: s,
        navItem: { path, name },
      });
    },
    {
      dependencies: [path, name],
    },
  );
  const handleSave = useCallback(
    (newTitle: string): void => {
      if (newTitle.length === 0) {
        createToast({
          message: `The new title was empty`,
          type: 'error',
        });
        newTitle = currentNodeTitle;
      }

      switch (contentType) {
        case 'external':
        case 'group':
          sendAppClientDataEvent({
            type: 'navs.item.update',
            id,
            navItem: {
              name: newTitle,
            },
          });
          break;
        case 'pattern':
          sendAppClientDataEvent({
            type: 'patterns.updateTitle',
            patternId: contentId,
            title: newTitle,
          });
          break;

        case 'page': {
          sendAppClientDataEvent({
            type: 'pages.updateTitle',
            pageId: contentId,
            title: newTitle,
          });
          break;
        }
      }
    },
    [contentType, id, currentNodeTitle, contentId],
  );

  const [isEditingNavItem, setIsEditingNavItem] = useState(false);
  const [currentText, setCurrentText] = useState(currentNodeTitle);
  const textEl = useRef<HTMLSpanElement>();
  const textInputElem = useRef<HTMLInputElement>();
  const wrapperEl = useRef<HTMLDivElement>();

  const focusOnText = () => {
    textInputElem?.current?.focus();
  };

  const deselectText = useCallback(() => {
    window.getSelection().removeAllRanges();
  }, []);

  const startEditing = () => {
    setIsEditingNavItem(true);
    sendUiEvent('nav.startedEditing');
    setTimeout(focusOnText, 0);
  };

  const errorMsgs: InlineEditErrorMsgs = {
    minLength: `Please enter a value that's at least 2 characters long.`,
    required: `Please enter text for your Nav Item.`,
  };

  const validateInput = (): void => {
    const elem = textInputElem.current;
    const validityState: HTMLInputElement['validity'] = elem.validity;

    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLObjectElement/setCustomValidity
    if (validityState.valueMissing || validityState.tooShort) {
      if (validityState.valueMissing && errorMsgs.required) {
        elem.setCustomValidity(errorMsgs.required);
      } else if (validityState.tooShort && errorMsgs.minLength) {
        elem.setCustomValidity(errorMsgs.minLength);
      }
      elem.reportValidity();
    } else {
      elem.setCustomValidity('');
    }
  };

  const save = useCallback(() => {
    if (textInputElem.current.validity.valid) {
      if (currentNodeTitle !== currentText) handleSave(currentText);
    } else {
      setCurrentText(currentNodeTitle);
    }
    setIsEditingNavItem(!isEditingNavItem);

    setTimeout(() => {
      sendUiEvent('nav.stoppedEditing');
    }, 200);
    // setTimeout() is needed here so clicking to another ... edit button while
    // the current item is being editing works w/o a 2nd click
  }, [currentText, handleSave, isEditingNavItem, currentNodeTitle]);

  const cancel = () => {
    setIsEditingNavItem(false);
    sendUiEvent('nav.stoppedEditing');

    if (currentNodeTitle !== currentText) setCurrentText(currentNodeTitle);
    setTimeout(deselectText, 0);
  };

  // When title from CONTEXT changes, set local state
  useEffect(() => {
    setCurrentText(currentNodeTitle);
  }, [currentNodeTitle]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!(event.target instanceof Node)) return;
      if (wrapperEl.current && !wrapperEl.current.contains(event.target)) {
        save();
      }
    };

    if (isEditingNavItem) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditingNavItem, save]);

  useEffect(() => {
    if (isEditingNavItem && isDragging) {
      save();
    }

    if (isDragging && isSubMenuOpen) {
      setIsSubMenuOpen(false);
    }
  }, [isEditingNavItem, save, isDragging, isSubMenuOpen, setIsSubMenuOpen]);
  const currentPathname = usePathname();
  const pathname = isNavItemExternal ? node.path : createUrl(node.path);
  return (
    <div
      className={`ks-c-legacy-nav-item
        ${isHidden ? 'ks-c-legacy-nav-item--hidden' : ''}
        ${!canEdit ? 'ks-c-legacy-nav-item--read-only' : ''}`}
      ref={wrapperEl}
    >
      {/* eslint-disable no-nested-ternary */}
      {node.path ? (
        <Link
          className={`rstcustom__rowLink ${
            currentPathname === pathname ? 'ks-c-legacy-nav-item--active' : ''
          }`}
          target={isNavItemExternal ? '_blank' : undefined}
          href={{
            pathname,
          }}
        >
          {isEditingNavItem ? (
            // @todo: combine this + similar Input element around line 438
            <input
              data-test-id="nav-item-rename-input"
              type="text"
              className="ks-c-legacy-nav-item__text-input"
              ref={textInputElem}
              value={currentText}
              required
              minLength={2}
              onClick={(e) => {
                // @todo: refactor to swap rendering the <Link> while editing vs using this click event workaround
                e.preventDefault();
              }}
              onBlur={() => {
                save();
              }}
              onPaste={(e) => {
                // Convert pasted text to plain text.
                // https://stackoverflow.com/a/34876744/1590987
                e.preventDefault();

                const content = e?.clipboardData?.getData('text/plain');

                if (document.queryCommandSupported('insertText')) {
                  document.execCommand('insertText', false, content);
                } else {
                  document.execCommand('paste', false, content);
                }
              }}
              onChange={(event) => {
                setCurrentText(event.target.value);
                validateInput();
              }}
              onInput={(event) => {
                validateInput();
              }}
              onKeyDown={(e) => {
                // enter key
                if (e.which === 13) {
                  save();
                }
                // esc key
                if (e.which === 27) {
                  cancel();
                }
              }}
            />
          ) : (
            <span className="ks-c-legacy-nav-item__text" ref={textEl}>
              {minRoleNeeded && (
                <Icon
                  className="ks-c-legacy-nav-item__private"
                  symbol="locked"
                  size="xsmall"
                />
              )}
              {currentText}
              {isNavItemExternal && (
                <Icon
                  className={componentSpacing({ marginLeft: 'xsmall' })}
                  symbol="link-external"
                  size="xsmall"
                />
              )}
            </span>
          )}
        </Link>
      ) : isEditingNavItem ? (
        <input
          data-test-id="nav-item-rename-input"
          type="text"
          className="ks-c-legacy-nav-item__text-input"
          ref={textInputElem}
          value={currentText}
          onClick={(e) => {
            // @todo: refactor to swap rendering the <Link> while editing vs using this click event workaround
            e.preventDefault();
          }}
          onBlur={save}
          onPaste={(e) => {
            // Convert pasted text to plain text.
            // https://stackoverflow.com/a/34876744/1590987
            e.preventDefault();

            const content = e?.clipboardData?.getData('text/plain');

            if (document.queryCommandSupported('insertText')) {
              document.execCommand('insertText', false, content);
            } else {
              document.execCommand('paste', false, content);
            }
          }}
          onChange={(event) => {
            setCurrentText(event.target.value);
          }}
          onKeyDown={(e) => {
            // enter key
            if (e.which === 13) {
              save();
            }
            // esc key
            if (e.which === 27) {
              cancel();
            }
          }}
        />
      ) : (
        <span className="ks-c-legacy-nav-item__text" ref={textEl}>
          {minRoleNeeded && (
            <Icon
              className="ks-c-legacy-nav-item__private"
              symbol="locked"
              size="xsmall"
            />
          )}
          {currentText}
        </span>
      )}
      {canEdit && (
        <div
          className="ks-c-legacy-nav-item__popup-trigger ks-popover__children"
          role="button"
          tabIndex={-1}
        >
          <NavTreeItemActions
            external={isNavItemExternal}
            id={node.id}
            handleRename={() => startEditing()}
          />
        </div>
      )}
    </div>
  );
};
