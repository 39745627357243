import { KnapsackNavItem } from '@knapsack/types';
import { secondaryNavRootKey } from '@/utils/constants';

export function getTopNavItem({
  allNavItems,
  navItem,
}: {
  allNavItems: KnapsackNavItem[];
  navItem: KnapsackNavItem;
}): KnapsackNavItem {
  if (navItem.parentId === secondaryNavRootKey) return navItem;
  const parentItem = allNavItems.find(({ id }) => navItem.parentId === id);
  if (!parentItem) {
    throw new Error(
      `Cannot find parent nav item "${navItem.parentId}" for nav item "${navItem.id}"`,
    );
  }
  return getTopNavItem({
    allNavItems,
    navItem: parentItem,
  });
}
